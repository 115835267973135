export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ISO8601DateTime: { input: any; output: any; }
};

/** Access token */
export type AccessToken = {
  __typename?: 'AccessToken';
  accessToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  expiresIn?: Maybe<Scalars['String']['output']>;
  redirectUri?: Maybe<Scalars['String']['output']>;
  resourceOwnerId?: Maybe<Scalars['ID']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  tokenType?: Maybe<Scalars['String']['output']>;
};

export type AccountMutation = {
  __typename?: 'AccountMutation';
  /** sends a forgot password email link */
  forgotPassword?: Maybe<StatusCode>;
  /** Checks a Twilio verification code with the phone number */
  mfaCheckVerificationCode?: Maybe<AccessToken>;
  /** Send a Twilio verification code to the phone number */
  mfaSendVerificationCode?: Maybe<Mfa>;
  /** registers a patient */
  register?: Maybe<StatusCode>;
  /** submits form information to register user */
  registerInvitation?: Maybe<AuthenticationResponse>;
  /** resends an email invitation link */
  resendInvitation?: Maybe<StatusCode>;
  /** sends a forgot password email link */
  resetPassword?: Maybe<AccessToken>;
  /** signs in a user and hands back the token */
  signIn?: Maybe<AuthenticationResponse>;
  /** signs in a user and hands back the token */
  signInEmail?: Maybe<SignInEmailPayload>;
  /** Tracks an analytics event through segment */
  trackEvent?: Maybe<StatusCode>;
};


export type AccountMutationForgotPasswordArgs = {
  dob?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};


export type AccountMutationMfaCheckVerificationCodeArgs = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  code: Scalars['String']['input'];
  mfaChannel: MfaSendChannelEnum;
  mfaToken: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type AccountMutationMfaSendVerificationCodeArgs = {
  mfaChannel?: InputMaybe<MfaSendChannelEnum>;
  mfaToken: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type AccountMutationRegisterArgs = {
  input: Scalars['String']['input'];
};


export type AccountMutationRegisterInvitationArgs = {
  input: RegisterInvitationInput;
};


export type AccountMutationResendInvitationArgs = {
  email: Scalars['String']['input'];
};


export type AccountMutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};


export type AccountMutationSignInArgs = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type AccountMutationSignInEmailArgs = {
  email: Scalars['String']['input'];
};


export type AccountMutationTrackEventArgs = {
  eventName: Scalars['String']['input'];
};

export type AccountQuery = {
  __typename?: 'AccountQuery';
  /** Determines if token is valid */
  validInvitationToken: InvitationTokenResponse;
};


export type AccountQueryValidInvitationTokenArgs = {
  fromExternalSource: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
};

/** Response from a sign in or create account mutation */
export type AuthenticationResponse = AccessToken | Mfa;

/** Response from an invitation token check */
export type InvitationTokenResponse = StatusCode | TermsAndAgreementsConfig;

/** MFA response */
export type Mfa = {
  __typename?: 'Mfa';
  mfaChannel: MfaSendChannelEnum;
  mfaToken: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  redirectPath: Scalars['String']['output'];
};

export enum MfaSendChannelEnum {
  /** Call phone number with MFA code */
  Call = 'call',
  /** Send SMS to phone number with MFA code */
  Sms = 'sms'
}

/** Params for a patient registering */
export type RegisterInvitationInput = {
  agreeToFinancialResponsibility: Scalars['Boolean']['input'];
  agreeToInformedConsent: Scalars['Boolean']['input'];
  agreeToPrivacyPolicy: Scalars['Boolean']['input'];
  agreeToPrivacyPractices: Scalars['Boolean']['input'];
  agreeToTerms: Scalars['Boolean']['input'];
  agreeToTextMessageMarketingAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  invitationToken: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

/** Autogenerated return type of SignInEmail. */
export type SignInEmailPayload = {
  __typename?: 'SignInEmailPayload';
  ssoConfig?: Maybe<SsoConfig>;
};

/** SSO Config for a providerr */
export type SsoConfig = {
  __typename?: 'SsoConfig';
  id: Scalars['ID']['output'];
  signInUrl: Scalars['String']['output'];
};

/** A status code with an optional message */
export type StatusCode = {
  __typename?: 'StatusCode';
  message?: Maybe<Scalars['String']['output']>;
  statusCode: Scalars['Int']['output'];
};

/** Resolved plan and config info based on an invitation token lookup */
export type TermsAndAgreementsConfig = {
  __typename?: 'TermsAndAgreementsConfig';
  customerName?: Maybe<Scalars['String']['output']>;
  enableTextMessageMarketingAgreement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  informedConsent?: Maybe<Scalars['String']['output']>;
  noticeOfPrivacyPractices?: Maybe<Scalars['String']['output']>;
  patientFinancialResponsibility?: Maybe<Scalars['String']['output']>;
  privacyPolicy: Scalars['String']['output'];
  programName?: Maybe<Scalars['String']['output']>;
  termsAndAgreementsEnabled: Scalars['Boolean']['output'];
  termsOfService: Scalars['String']['output'];
};

export type ForgotPasswordMutationVariables = Exact<{
  dob?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
}>;


export type ForgotPasswordMutation = { __typename?: 'AccountMutation', forgotPassword?: { __typename?: 'StatusCode', message?: string | null, statusCode: number } | null };

export type MfaCheckVerificationCodeMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  code: Scalars['String']['input'];
  mfaChannel: MfaSendChannelEnum;
  mfaToken: Scalars['String']['input'];
  phone: Scalars['String']['input'];
}>;


export type MfaCheckVerificationCodeMutation = { __typename?: 'AccountMutation', mfaCheckVerificationCode?: { __typename?: 'AccessToken', accessToken?: string | null } | null };

export type MfaSendVerificationCodeMutationVariables = Exact<{
  mfaToken: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  mfaChannel?: InputMaybe<MfaSendChannelEnum>;
}>;


export type MfaSendVerificationCodeMutation = { __typename?: 'AccountMutation', mfaSendVerificationCode?: { __typename?: 'Mfa', mfaChannel: MfaSendChannelEnum, mfaToken: string, phoneNumber?: string | null, redirectPath: string } | null };

export type RegisterInvitationMutationVariables = Exact<{
  input: RegisterInvitationInput;
}>;


export type RegisterInvitationMutation = { __typename?: 'AccountMutation', registerInvitation?: { __typename?: 'AccessToken', accessToken?: string | null } | { __typename?: 'Mfa', mfaToken: string, phoneNumber?: string | null, redirectPath: string } | null };

export type RegisterMutationVariables = Exact<{
  input: Scalars['String']['input'];
}>;


export type RegisterMutation = { __typename?: 'AccountMutation', register?: { __typename?: 'StatusCode', statusCode: number } | null };

export type ResendInvitationMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ResendInvitationMutation = { __typename?: 'AccountMutation', resendInvitation?: { __typename?: 'StatusCode', message?: string | null, statusCode: number } | null };

export type ResetPasswordMutationVariables = Exact<{
  resetPasswordToken: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'AccountMutation', resetPassword?: { __typename?: 'AccessToken', accessToken?: string | null, createdAt?: any | null, expiresIn?: string | null, scope?: string | null, tokenType?: string | null } | null };

export type SignInEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SignInEmailMutation = { __typename?: 'AccountMutation', signInEmail?: { __typename?: 'SignInEmailPayload', ssoConfig?: { __typename?: 'SsoConfig', id: string, signInUrl: string } | null } | null };

export type SignInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
}>;


export type SignInMutation = { __typename?: 'AccountMutation', signIn?: { __typename?: 'AccessToken', accessToken?: string | null, redirectUri?: string | null, resourceOwnerId?: string | null } | { __typename?: 'Mfa', mfaChannel: MfaSendChannelEnum, mfaToken: string, phoneNumber?: string | null, redirectPath: string } | null };

export type TrackEventMutationVariables = Exact<{
  eventName: Scalars['String']['input'];
}>;


export type TrackEventMutation = { __typename?: 'AccountMutation', trackEvent?: { __typename?: 'StatusCode', statusCode: number } | null };

export type ValidInvitationTokenQueryVariables = Exact<{
  token: Scalars['String']['input'];
  fromExternalSource: Scalars['Boolean']['input'];
}>;


export type ValidInvitationTokenQuery = { __typename?: 'AccountQuery', validInvitationToken: { __typename?: 'StatusCode', message?: string | null, statusCode: number } | { __typename?: 'TermsAndAgreementsConfig', id: string, customerName?: string | null, enableTextMessageMarketingAgreement?: boolean | null, informedConsent?: string | null, noticeOfPrivacyPractices?: string | null, patientFinancialResponsibility?: string | null, privacyPolicy: string, programName?: string | null, termsAndAgreementsEnabled: boolean, termsOfService: string } };
