import Alert from '@mui/material/Alert'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import FooterDiv from '@app/account/components/elements/FooterDiv'
import TermsAndPrivacyPolicy from '@app/account/components/elements/TermsAndPrivacyPolicy'
import MFA_SEND_VERIFICATION_CODE_MUTATION from '@app/account/graphql/MfaSendVerificationCodeMutation.gql'
import { useI18n } from '@app/configuration/contexts/I18nContext'
import useCustomer from '@app/configuration/hooks/useCustomer'
import {
  MfaSendChannelEnum,
  MfaSendVerificationCodeMutation,
  MfaSendVerificationCodeMutationVariables,
  StatusCode,
} from '@app/graphql-types/Account'
import Form, {
  ElementType,
  FormFieldWrapper,
} from '@app/src/components/forms/Form'
import styled from '@app/src/constants/theme'
import { useMutationResult } from '@app/src/hooks/useMutationResult'

const AddPhoneNumberPage = () => {
  const { pageTitle } = useCustomer()
  const i18n = useI18n()
  const location = useLocation()
  const navigate = useNavigate()
  const [errorText, setErrorText] = React.useState('')
  const defaultValues = {
    phone: location.state?.phone || '',
    mfaChannel: location.state?.mfaChannel,
  }
  const defaultErrorMessage = i18n.t('mfa.sendCodeErrorMessage')

  const VALIDATION_SCHEMA = yup.object().shape({
    phone: yup.string().required(i18n.t('validations.required')),
  })

  const { mutate } = useMutationResult<
    MfaSendVerificationCodeMutation,
    MfaSendVerificationCodeMutationVariables,
    StatusCode
  >({
    mutation: MFA_SEND_VERIFICATION_CODE_MUTATION,
    successField: 'mfaSendVerificationCode',
  })

  const resetErrorText = React.useCallback(() => {
    setErrorText('')
  }, [])

  const handleSubmit = React.useCallback(
    (formData: { phone: string, mfaChannel: MfaSendChannelEnum }) => {
      setErrorText('')
      mutate({ mfaToken: location.state?.mfaToken, ...formData })
        .then(({ data, errors }) => {
          if (errors?.length) {
            const msg = errors[0].message || defaultErrorMessage
            setErrorText(msg)
            return
          }

          const mfaData = data?.mfaSendVerificationCode
          if (mfaData) {
            navigate(mfaData.redirectPath, {
              state: {
                mfaChannel: mfaData.mfaChannel,
                mfaToken: mfaData.mfaToken,
                phone: mfaData.phoneNumber,
              },
            })
          }
        })
        .catch((_serverError) => {
          setErrorText(defaultErrorMessage)
        })
    },
    [defaultErrorMessage, location.state?.mfaToken, mutate, navigate],
  )

  return (
    <React.Fragment>
      <Helmet
        title={`${pageTitle} - ${i18n.t('mfa.sendVerificationCodePage.pageTitle')}`}
      />

      <Wrapper>
        <h1>{i18n.t('mfa.sendVerificationCodePage.header')}</h1>
        <p>{i18n.t('mfa.sendVerificationCodePage.body')}</p>

        <Form
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          shouldEnableWhenDirty
          submitButtonText={i18n.t('mfa.sendVerificationCodePage.sendCode')}
          validationSchema={VALIDATION_SCHEMA}
        >
          <FormFieldWrapper
            label={i18n.t('mfa.sendVerificationCodePage.phoneLabel')}
            name="phone"
            onChanged={resetErrorText}
            type={ElementType.TEL}
          />
          {/* the reverse below is to get the sms option before the call option */}
          <FormFieldWrapper
            className="MfaChannel"
            label={i18n.t('mfa.sendVerificationCodePage.selectMethodLabel')}
            name="mfaChannel"
            options={Object.values(MfaSendChannelEnum).reverse().map((value) => ({
              label: i18n.t(`mfa.sendVerificationCodePage.${value}OptionLabel`),
              value,
            }))}
            type={ElementType.RADIO}
          />
        </Form>
        {Boolean(errorText.length) && (
          <Alert onClose={resetErrorText} severity="error" variant="filled">
            {errorText}
          </Alert>
        )}
      </Wrapper>
      <FooterDiv>
        <TermsAndPrivacyPolicy />
      </FooterDiv>
    </React.Fragment>
  )
}

const Wrapper = styled.div({
  '& .FormSubmit': {
    '& > button': {
      marginTop: 10,
      width: '100%',
    },
    flexDirection: 'column',
  },
  '& > h1': {
    marginBottom: 20,
  },
  '& > p': {
    marginBottom: 30,
  },
  '& .MfaChannel': {
    marginTop: 10,
    textAlign: 'left',
  },
  '.MuiAlert-root': {
    marginTop: 20,
    textAlign: 'left',
  },
  margin: '0 auto',
  maxWidth: 500,
  textAlign: 'center',
  width: '100%',
})

export default AddPhoneNumberPage
