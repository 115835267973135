import Alert from '@mui/material/Alert'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import * as yup from 'yup'

import FooterDiv from '@app/account/components/elements/FooterDiv'
import TermsAndPrivacyPolicy from '@app/account/components/elements/TermsAndPrivacyPolicy'
import MFA_CHECK_VERIFICATION_CODE_MUTATION from '@app/account/graphql/MfaCheckVerificationCodeMutation.gql'
import MFA_SEND_VERIFICATION_CODE_MUTATION from '@app/account/graphql/MfaSendVerificationCodeMutation.gql'
import { useI18n } from '@app/configuration/contexts/I18nContext'
import useCustomer from '@app/configuration/hooks/useCustomer'
import {
  AccessToken,
  Mfa,
  MfaCheckVerificationCodeMutation,
  MfaCheckVerificationCodeMutationVariables,
  MfaSendVerificationCodeMutation,
  MfaSendVerificationCodeMutationVariables,
} from '@app/graphql-types/Account'
import PillButton from '@app/src/components/elements/PillButton'
import Form, {
  ElementType,
  FormFieldWrapper,
} from '@app/src/components/forms/Form'
import styled from '@app/src/constants/theme'
import { FormData } from '@app/src/hooks/useForm'
import { useMutationResult } from '@app/src/hooks/useMutationResult'

const VALIDATION_SCHEMA = yup.object().shape({
  code: yup.string().required('Code is required'),
})

const CheckVerificationCodePage = () => {
  const { clientId, clientSecret, pageTitle } = useCustomer()
  const i18n = useI18n()
  const location = useLocation()
  const [errorText, setErrorText] = React.useState('')
  const defaultValues = { code: '' }
  const defaultCheckErrorMessage = i18n.t('mfa.verifyCodeErrorMessage')
  const defaultResendErrorMessage = i18n.t('mfa.sendCodeErrorMessage')

  const { mutate: checkMutation } = useMutationResult<
    MfaCheckVerificationCodeMutation,
    MfaCheckVerificationCodeMutationVariables,
    AccessToken
  >({
    mutation: MFA_CHECK_VERIFICATION_CODE_MUTATION,
    successField: 'mfaCheckVerificationCode',
  })

  const { mutate: sendMutation } = useMutationResult<
    MfaSendVerificationCodeMutation,
    MfaSendVerificationCodeMutationVariables,
    Mfa
  >({
    mutation: MFA_SEND_VERIFICATION_CODE_MUTATION,
    successField: 'mfaSendVerificationCode',
  })

  const handleResendCode = React.useCallback(() => {
    setErrorText('')
    sendMutation({
      mfaChannel: location.state?.mfaChannel,
      mfaToken: location.state?.mfaToken,
      phone: location.state?.phone,
    })
      .then(({ errors }) => {
        if (errors?.length) {
          const msg = errors[0].message || defaultResendErrorMessage
          setErrorText(msg)
          return
        }
      })
      .catch((_serverError) => {
        setErrorText(defaultResendErrorMessage)
      })
  }, [defaultResendErrorMessage, location.state, sendMutation])

  const resetErrorText = React.useCallback(() => {
    setErrorText('')
  }, [])

  const handleSubmit = React.useCallback(
    (formData: FormData) => {
      setErrorText('')
      checkMutation({
        clientId,
        clientSecret,
        code: formData.code,
        mfaChannel: location.state?.mfaChannel,
        mfaToken: location.state?.mfaToken,
        phone: location.state?.phone,
      })
        .then(({ errors }) => {
          if (errors?.length) {
            const msg = errors[0].message || defaultCheckErrorMessage
            setErrorText(msg)
            return
          }

          window.location.href = '/'
        })
        .catch((_serverError) => {
          setErrorText(defaultCheckErrorMessage)
        })
    },
    [clientId, clientSecret, checkMutation, defaultCheckErrorMessage, location],
  )

  return (
    <React.Fragment>
      <Helmet
        title={`${pageTitle} - ${i18n.t('mfa.checkVerificationCodePage.pageTitle')}`}
      />

      <Wrapper>
        <h1>{i18n.t('mfa.checkVerificationCodePage.header')}</h1>
        <p>{`${i18n.t('mfa.checkVerificationCodePage.to')} +X XXX-XXX-${location.state?.phone.slice(-4)}.`}</p>

        <Form
          additionalButtons={[
            <PillButton onClick={handleResendCode}>
              {i18n.t('mfa.checkVerificationCodePage.resendCode')}
            </PillButton>,
          ]}
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
          submitButtonText={i18n.t('mfa.checkVerificationCodePage.verifyCode')}
          validationSchema={VALIDATION_SCHEMA}
        >
          <FormFieldWrapper
            autoFocus
            label={i18n.t('mfa.checkVerificationCodePage.codeLabel')}
            name="code"
            onChanged={resetErrorText}
            type={ElementType.TEXT}
          />
        </Form>
        {Boolean(errorText.length) && (
          <Alert onClose={resetErrorText} severity="error" variant="filled">
            {errorText}
          </Alert>
        )}
      </Wrapper>
      <FooterDiv>
        <TermsAndPrivacyPolicy />
      </FooterDiv>
    </React.Fragment>
  )
}

const Wrapper = styled.div({
  '& .FormSubmit': {
    '& > button': {
      marginLeft: 0,
      marginTop: 10,
      width: '100%',
    },
    flexDirection: 'column-reverse',
  },
  '& > h1': {
    marginBottom: 20,
  },
  '& > p': {
    marginBottom: 30,
  },
  '.MuiAlert-root': {
    marginTop: 20,
    textAlign: 'left',
  },
  margin: '0 auto',
  maxWidth: 500,
  textAlign: 'center',
  width: '100%',
})

export default CheckVerificationCodePage
